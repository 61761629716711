import axios from "axios";
import { getToken } from "./auth";

  // export const baseURL = "http://localhost/palma_travel_laravel";
  //  export const baseURL = "http://localhost:8000";
 export const baseURL = "https://backendtravel.palmaseguros.tech";
// export const baseURL = "https://test-backend.palmatravel.tech";

const api = axios.create({
  baseURL: baseURL + "/api",
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export { api };
